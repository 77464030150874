import React from 'react'
import { useSearchParams } from 'react-router-dom';
import Layout from './Layout';
import useUserState, { UserType } from '../hooks/useUserState';
import config from '../config';

type Props = {
    userState: UserType
}

const SubscriptionPage = ({ userState }: Props) => {
    let packText = "spécial"
    if (userState.status === 3)
        packText = "premium"
    else if (userState.status === 4)
        packText = "professionnel"

    let subscriptionTypeText = "Stripe Web"
    if (userState.product?.startsWith("hm_") && userState.status !== 2)
        subscriptionTypeText = "l'App Store"
    else if (userState.product?.startsWith("ahm_") && userState.status !== 2)
        subscriptionTypeText = "le Play Store"
    return (
        <Layout>
            <div className='text-center py-6'>
                <h1>Vous êtes abonné avec le pack {packText} avec <i>{subscriptionTypeText}</i></h1>
                {userState.product?.startsWith("prod_") && userState.status !== 2 ? <>
                    <a className='font-bold text-secondary text-xl' href="https://billing.stripe.com/p/login/dR6dUn2J69TmfKw7ss" target='_blank'>Gérer votre abonnement</a>
                    <div className='mt-4 text-sm'>Si vous n'êtes pas automatiquement connecté, connectez-vous avec votre email de facturation Stripe. Contactez-nous si besoin : <a className='underline hover:no-underline' href="mailto:contact@hoqi.app">contact@hoqi.app</a></div>
                </> : (userState.status === 2 ?
                    <div>Contactez-nous pour gérer votre abonnement : <a className='underline hover:no-underline' href="mailto:contact@hoqi.app">contact@hoqi.app</a></div>
                    : <div>Allez sur {subscriptionTypeText} pour gérer votre abonnement. Contactez-nous si besoin : <a className='underline hover:no-underline' href="mailto:contact@hoqi.app">contact@hoqi.app</a></div>)}
            </div>

            <div className='mx-auto flex flex-row justify-between w-80 gap-8 mt-8'>
                <div><a href="https://apps.apple.com/fr/app/hoqi-lia-pour-limmobilier/id6448754506" target='_blank'><img src='/disponible-app-store.png' /></a></div>
                <div><a href="https://play.google.com/store/apps/details?id=app.homni.homni" target='_blank'><img src='/disponible-play-store.png' /></a></div>
            </div>
        </Layout>
    );
}

export default SubscriptionPage
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { UserType } from '../../hooks/useUserState';
import { fetchAuthFB } from '../../api/ApiAuth';
import FacebookLogin, { FacebookLoginClient, SuccessResponse } from '@greatsumini/react-facebook-login';


// https://github.com/MomenSherif/react-oauth

type Props = {
    onUser: (user: {
        jwt_token: string;
        user: UserType;
    } | null) => void
}

const FacebookAuth = ({ onUser }: Props) => {
    const [isWaiting, setIsWaiting] = useState(false)

    const handlePressFB = (onClick: () => void) => {
        onClick()
        setIsWaiting(true)
    }

    const handleSuccess = async (response: SuccessResponse) => {
        console.log('Login Success!', response);
        if (!response?.accessToken) {
            throw new Error('Access response was not ok');
        }

        await getUser(response.accessToken)

        setIsWaiting(false)
    }

    const getUser = async (token: string) => {
        const user = await fetchAuthFB({ token, configCache: null })
        onUser(user)
    }

    return <FacebookLogin
        render={({ onClick }) => <button className='py-4 px-1 w-64 flex rounded-lg justify-center items-center bg-white hover:opacity-90' onClick={() => handlePressFB(onClick)}>
            <img src='/icon-fb.png' className='w-6 h-6 mr-4' />
            Se connecter avec Facebook
        </button>
        }
        appId={process.env.REACT_APP_FB_APP_ID}
        onSuccess={handleSuccess}
        onFail={(error) => {
            console.log('Login Failed!', error);
            setIsWaiting(false)

        }}
        onProfileSuccess={(response) => {
            console.log('Get Profile Success!', response);
            setIsWaiting(false)

        }}
    />

}


export default FacebookAuth
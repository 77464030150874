import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";

const useGlobalState = (key, initialData) => {
    const client = useQueryClient();
    return [
        useQuery(key, () => initialData, {
            cacheTime: 0,
            enabled: false,
            initialData,
        }).data,
        (value) => { client.setQueryData(key, value) },
    ]
}

export default useGlobalState

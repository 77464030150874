import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { UserType } from '../../hooks/useUserState';
import { fetchAuthGoogle } from '../../api/ApiAuth';

// https://github.com/MomenSherif/react-oauth

type Props = {
    onUser: (user: {
        jwt_token: string;
        user: UserType;
    } | null) => void
}

const GoogleAuth = ({ onUser }: Props) => {
    const [isWaiting, setIsWaiting] = useState(false)
    const [userInfo, setUserInfo] = useState<any>(undefined)
    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            console.log(tokenResponse);
            // fetching userinfo can be done on the client or the server
            const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const userInfo = await response.json();
            setUserInfo(userInfo)
            setIsWaiting(false)
            console.log(userInfo);
        },
        onError: error => {
            setIsWaiting(false)
            console.log({ error });
        },

        // flow: 'implicit', // implicit is the default
    });

    const handlePressGoogle = () => {
        setIsWaiting(true)
        googleLogin()
    }

    const getUser = async (userInfo: any) => {
        const res = await fetchAuthGoogle({ userInfo, configCache: null })
        onUser(res)
    }

    useEffect(() => {
        if (userInfo?.email) {
            getUser(userInfo)
        }
        else setIsWaiting(false)
    }, [userInfo]);


    // if (isWaiting) {
    //     return (
    //         <div>Waiting</div>
    //     )
    // }
    return (
        <button className='py-4 px-1 w-64 flex rounded-lg justify-center items-center bg-white hover:opacity-90' onClick={handlePressGoogle}>
            <img src='/icon-google.png' className='w-6 h-6 mr-4' />
            Se connecter avec Google
        </button>
    )
}

export default GoogleAuth
import { useState } from 'react';
import AppleSignin, { authOptions } from 'react-apple-signin-auth';
import { UserType } from '../../hooks/useUserState';
import { fetchAuthApple } from '../../api/ApiAuth';
import config from '../../config';

// https://github.com/a-tokyo/react-apple-signin-auth


type Props = {
    onUser: (user: {
        jwt_token: string;
        user: UserType;
    } | null) => void
}

/** Apple Signin button */
const AppleAuth = ({ onUser }: Props) => {
    const handleSuccess = async (data) => {
        console.log({ data })
        if (!data?.authorization?.code) {
            throw new Error('Network response was not ok');
        }
        const dataApple = { "origin": "web" }
        if (data?.user?.name) {
            const name = data?.user?.name
            if (name?.firstName)
                dataApple["first_name"] = name?.firstName
            if (name?.lastName)
                dataApple["last_name"] = name?.lastName
        }

        if (data?.authorization?.id_token) {
            const user = await fetchAuthApple({ dataApple, token: data?.authorization?.id_token, configCache: null })
            if (user)
                onUser(user)
        }


    }

    const handleError = (error: any) => {
        console.log({ error })
    }

    return <AppleSignin
        /** Auth options passed to AppleID.auth.init() */
        authOptions={{
            /** Client ID - eg: 'com.example.com' */
            clientId: config.appleSignIn.clientId[process.env.NODE_ENV],
            /** Requested scopes, seperated by spaces - eg: 'email name' */
            scope: 'email name',
            /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
            redirectURI: config.appleSignIn.redirectURL[process.env.NODE_ENV],
            /** State string that is returned with the apple response */
            state: 'state',
            /** Nonce */
            nonce: 'nonce',
            /** Uses popup auth instead of redirection */
            usePopup: true
        }} // REQUIRED
        /** General props */
        uiType="dark"
        /** className */
        className="apple-auth-btn"
        /** Removes default style tag */
        noDefaultStyle={false}
        /** Allows to change the button's children, eg: for changing the button text */
        buttonExtraChildren="Continue with Apple"
        /** Extra controlling props */
        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
        onSuccess={handleSuccess} // default = undefined
        /** Called upon signin error */
        onError={handleError} // default = undefined
        /** Skips loading the apple script if true */
        skipScript={false} // default = undefined
        /** Apple image props */
        iconProp={{ style: { marginTop: '10px' } }} // default = undefined
        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
        render={(props) => <ButtonAppleSignin {...props} />}
    />
};

const ButtonAppleSignin = ({ onClick }) => {
    return <button className='py-4 px-1 w-64 flex rounded-lg justify-center items-center bg-white hover:opacity-90' onClick={onClick}>
        <img src='/icon-apple-black.png' className='w-6 h-6 mr-4' />
        Se connecter avec Apple
    </button>
}

export default AppleAuth
import config from "../config"
import { UserAccessType, UserType } from "../hooks/useUserState"
import { getJWTToken } from "../modules/secure"

export const fetchAuthGoogle = async ({ userInfo, configCache }: { userInfo: any, configCache: any }): Promise<{ jwt_token: string, user: UserType } | null> => {
    try {
        const res = await fetch(config.apiModelEndpoint + '/auth/', {
            method: 'POST',
            headers: {
                //"credentials": 'include',
                'X-Google-OAuth2-Type': 'client',
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ idinfo: userInfo, meta: { "os": "web" } })
        })
        const data = await res.json()

        //const access = getAccessByStatus(configCache, data.user.status)

        return {
            "jwt_token": data.jwt_token,
            "user": {
                "id": data.user.id,
                "login": data.user.login,
                "firstName": data.user.firstname,
                "lastName": data.user.lastname,
                "status": data.user.status,
                "product": data.user.product,
                "quota": data.user.quota,
                "mention": data.user.mention,
                "createDate": data.user.create_date,
                "isNew": data.user.is_new,
                "subscriptionPeriod": data.user.subscription_period,
                "subscriptionRenewDate": data.user.subscription_renew_date,
                "metaparams": data.user.metaparams,
                //"access": access,

            }
        }
    } catch (e) {
        console.log(e)
        return null
    }
}

export const fetchAuthApple = async ({ dataApple, token, configCache }: { dataApple: any, token: string, configCache: any }): Promise<{ jwt_token: string, user: UserType } | null> => {
    try {
        const res = await fetch(config.apiModelEndpoint + '/auth/', {
            method: 'POST',
            headers: {
                //"credentials": 'include',
                "Auth-Provider": 'apple',
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ apple_data: dataApple, token, meta: { "os": "web" } })
        })
        const data = await res.json()

        console.log({ data })

        return {
            "jwt_token": data.jwt_token,
            "user": {
                "id": data.user.id,
                "login": data.user.login,
                "firstName": data.user.firstname,
                "lastName": data.user.lastname,
                "status": data.user.status,
                "product": data.user.product,
                "quota": data.user.quota,
                "mention": data.user.mention,
                "expoPushToken": data.user.expo_push_token,
                "createDate": data.user.create_date,
                "isNew": data.user.is_new,
                "subscriptionPeriod": data.user.subscription_period,
                "subscriptionRenewDate": data.user.subscription_renew_date,
                "metaparams": data.user.metaparams
            }
        }
    } catch (e) {
        console.log(e)
        return null
    }
}


export const fetchAuthFB = async ({ token, configCache }: { token: string, configCache: any }): Promise<{ jwt_token: string, user: UserType } | null> => {
    try {
        const res = await fetch(config.apiModelEndpoint + '/auth/', {
            method: 'POST',
            headers: {
                //"credentials": 'include',
                "Auth-Provider": 'fb',
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token, meta: { "os": 'web' } })
        })
        const data = await res.json()

        return {
            "jwt_token": data.jwt_token,
            "user": {
                "id": data.user.id,
                "login": data.user.login,
                "firstName": data.user.firstname,
                "lastName": data.user.lastname,
                "status": data.user.status,
                "product": data.user.product,
                "quota": data.user.quota,
                "mention": data.user.mention,
                "expoPushToken": data.user.expo_push_token,
                "createDate": data.user.create_date,
                "isNew": data.user.is_new,
                "subscriptionPeriod": data.user.subscription_period,
                "subscriptionRenewDate": data.user.subscription_renew_date,
                "metaparams": data.user.metaparams
            }
        }
    } catch (e) {
        console.log(e)
        return null
    }
}

export const fetchValidJWT = async ({ jwtToken, configCache }: { jwtToken?: string | null, configCache: any }): Promise<UserType | null> => {
    let token = jwtToken
    if (!jwtToken) token = getJWTToken()

    try {
        const res = await fetch(config.apiModelEndpoint + '/auth/validate', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ jwt_token: token })
        })
        const data = await res.json()

        if (!jwtToken && res.status === 401) // !jwtToken because it's a case of this function is call later in the app and not during the authpage
            throw new Error("status:401 - Authorization error7")

        return {
            "id": data.id,
            "login": data.login,
            "firstName": data.firstname,
            "lastName": data.lastname,
            "status": data.status,
            "product": data.product,
            "quota": data.quota,
            "mention": data.mention,
            "createDate": data.create_date,
            "subscriptionPeriod": data.subscription_period,
            "subscriptionRenewDate": data.subscription_renew_date,
            "metaparams": data.metaparams
        }
    } catch (e) {
        console.log(e)
        return null
    }
}
import React, { useEffect, useState } from 'react'
import useUserState, { UserType } from '../hooks/useUserState'
import AccountPage from './AccountPage'
import GoogleAuth from '../components/auth/GoogleAuth'
import { getJWTToken, saveJWTToken } from '../modules/secure'
import { fetchValidJWT } from '../api/ApiAuth'
import AppleAuth from '../components/auth/AppleAuth'
import config from '../config'
import DashboardPage from './DashboardPage'
import PricingPage from './PricingPage'
import Layout from './Layout'
import SubscriptionPage from './SubscriptionPage'
import { Link } from 'react-router-dom'

type Props = {}

const PreAuthPage = (props: Props) => {


    return (
        <div className='flex-1 flex-col h-screen w-full bg-primary flex justify-center items-center p-4'>
            <a href='https://hoqi.app'><img className='h-32' src='/logo-white.png' alt='logo' /></a>
            <div className='mt-8'>
                <div className='text-white text-center mt-8 font-bold text-base'>HOQI est disponible SEULEMENT sur Smartphone et Tablette.</div>
                <div className='text-white text-center mt-8'>1 - Téléchargez HOQI et testez gratuitement :</div>
                <div className='mx-auto flex flex-row justify-between w-80 gap-8 mt-4'>
                    <div><a href="https://apps.apple.com/fr/app/hoqi-lia-pour-limmobilier/id6448754506" target='_blank'><img src='/disponible-app-store.png' /></a></div>
                    <div><a href="https://play.google.com/store/apps/details?id=app.homni.homni" target='_blank'><img src='/disponible-play-store.png' /></a></div>
                </div>
            </div>

            <div className='max-w-md mt-24'>
                <div className='text-white mb-2'>2 - Pour vous abonner et si vous avez déjà un compte mobile ?</div>
                <Link to='/login' className='mx-auto py-4 px-1 w-72 flex rounded-lg justify-center items-center bg-white hover:opacity-90 text-base'>
                    Accéder à la page d'abonnement
                </Link>
            </div>

            <div className='text-xs mt-8 text-white text-center'><span className='font-bold'>Une question ?</span> <a className='underline hover:no-underline' href="mailto:contact@hoqi.app">contact@hoqi.app</a>
            </div>
        </div>
    )
}

export default PreAuthPage
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import Layout from './Layout';
import useUserState from '../hooks/useUserState';
import config from '../config';

// If using TypeScript, add the following snippet to your file as well.
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

type Props = {}

const PricingPage = (props: Props) => {
    //const [searchParams, setSearchParams] = useSearchParams()
    //const login_b64 = btoa(searchParams.get("login")).replaceAll("=", "_")
    const { userState } = useUserState()
    const login_b64 = btoa(userState["login"]).replaceAll("=", "_")


    return (
        <Layout>
            <div className='text-center py-6'>
                <h1>Choisissez votre abonnement</h1>
                <div>Choisissez "Annuel" pour une réduction de 20%. Les prix affichés sont TTC.</div>
            </div>
            <stripe-pricing-table
                pricing-table-id={config.stripe.paywallTableId[process.env.NODE_ENV]}
                publishable-key={config.stripe.paywallKey[process.env.NODE_ENV]}
                client-reference-id={login_b64}
            >
            </stripe-pricing-table>
            <div className='text-center text-sm mt-4'>Après votre abonnement, redémarrez l'application sur votre mobile pour qu'il soit pris en compte.</div>
            <div className='text-center mt-12 mb-6'><span className='text-base font-bold'>Une question ?</span><br />
                <span className='text-small'><a className='underline hover:no-underline' href="mailto:contact@hoqi.app">contact@hoqi.app</a></span>
            </div>
        </Layout>
    );
}

export default PricingPage
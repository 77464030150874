import React from 'react'
import useUserState from '../hooks/useUserState'
import { saveJWTToken } from '../modules/secure'

type Props = {
    children: React.ReactNode
}

const Layout = ({ children }: Props) => {
    return (<div className='flex flex-col h-screen bg-primary'>
        <Header />
        <div className='flex-grow p-4 bg-white'>
            {children}
        </div>
        <Footer />
    </div>
    )
}

const Header = () => {
    const { userState, setUserState } = useUserState()
    const handleSignout = () => {
        setUserState('expired')
        saveJWTToken(undefined)
    }
    return (
        <header className='w-full h-24 bg-primary p-6'>
            <div className='text-center'>
                <a href='https://hoqi.app'><img className='h-10 inline' src='/logo-horiz-white.png' alt='logo' /></a>
            </div>
            {userState && userState["login"] ?
                <div className='text-white text-sm absolute right-4 top-4 text-right'>
                    <div className='font-bold hidden sm:block'>{userState["firstName"]} {userState["lastName"]}</div>
                    <div className='hidden sm:block'>{userState["login"] && !userState["login"].includes('privaterelay.appleid') ? userState["login"] : null}</div>
                    <div><button className='right-0 underline hover:no-underline' onClick={handleSignout}>Quitter</button></div>
                </div> : null}
        </header>
    )
}

const Footer = () => {
    const { userState } = useUserState()

    return (
        <footer className='w-full bg-primary p-4'>
            <div className='text-white text-sm text-center'>© Tous droits réservés. • HOQI - L'intelligence artificielle au service de l'immobilier
                <span className='opacity-50'> - Id : {userState && userState["id"] ? ' #5329' + userState["id"] : null}</span>
            </div>
        </footer>
    )
}

export default Layout
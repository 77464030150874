export default {
    //apiModelEndpoint: 'http://192.168.0.18:8000/api/v1',
    //apiModelEndpoint: 'http://192.168.1.15:8000/api/v1',
    apiModelEndpoint: 'https://api-room.mldatago.com/api/v1', // new prod
    //apiModelEndpoint: 'https://api-homni.mldatago.com/api/v1', // backup prod
    //apiModelEndpoint: 'https://sd5.mldatago.com/api/v1', // dev/preprod
    //apiModelEndpoint: 'https://albacore-natural-moderately.ngrok-free.app',
    staticsEndpoint: 'https://cdn1.gomlai.com/html',
    appleSignIn: {
        redirectURL: {
            development: 'https://albacore-natural-moderately.ngrok-free.app',
            production: 'https://dashboard.hoqi.app'
        },
        clientId: {
            development: 'app.ngrok-free.albacore-natural-moderately',
            production: 'app.hoqi.dashboard'
        },
    },
    stripe: {
        paywallTableId: {
            development: 'prctbl_1NkThdGCdoO2voWiTj8t6g8Z',
            production: 'prctbl_1Npv7YGCdoO2voWiOVgzqVyp'
        },
        paywallKey: {
            development: 'pk_test_51NkRSZGCdoO2voWiGn5ICbNaRrmSIDhANdfNqBIwLQtOxr5XkP7CrWXzK17wVmVb27krEjm9EcOLmOlsOmXmFBfj008jz5WRMQ',
            production: 'pk_live_51NkRSZGCdoO2voWiyDdBY9HS0YGEWxE2ptVSVssUVCJWPFZQMq9UmPxdV4NZ6taecmP4HhTKfOfXjvznbQYGHsgW00vcZuUJPn'
        },
    }
}
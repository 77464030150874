// Functions to interact with localStorage

export function saveInSecuredStore(key: string, value: string) {
    try {
        localStorage.setItem(key, value);
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export function getFromSecuredStore(key: string) {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        console.log(e);
        return null;
    }
}

export function deleteFromSecuredStore(key: string) {
    try {
        localStorage.removeItem(key);
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export function saveJWTToken(jwtToken: string | undefined) {
    if (jwtToken) {
        return saveInSecuredStore("jwToken", jwtToken);
    } else {
        return deleteFromSecuredStore("jwToken");
    }
}

export function getJWTToken() {
    return getFromSecuredStore("jwToken");
}

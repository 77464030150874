import useGlobalState from './useGlobalState';

export type UserType = {
    id: number,
    login: string,
    firstName: string,
    lastName: string,
    status: number,
    product?: string,
    quota: number,
    createDate: string,
    mention: string,
    jwtToken?: string,
    expoPushToken?: object,
    isNew?: boolean,
    subscriptionPeriod?: number,
    subscriptionRenewDate?: string,
    access?: UserAccessType,
    metaparams?: object,
}

export type UserAccessType = ["MENTION" | "PROJECTS"] | []

const useUserState = () => {
    const [userState, setUserState] = useGlobalState(["userstate"], null);

    return { userState, setUserState }
}

export default useUserState
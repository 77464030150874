import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { MaskEditor } from '../components/react-mask/maskEditor'
//import { MaskEditor, toMask } from "react-mask-editor"


type Props = {}

const ImageMaskPage = (props: Props) => {
    return (
        <div className='w-full h-full'>
            <MaskImage />
        </div>
    )
}

type MaskImageProps = {}

const MaskImage = (props: MaskImageProps) => {
    const canvas = React.useRef<HTMLCanvasElement>();

    // I want to have the w andh vars of get params of the url
    const [searchParams, setSearchParams] = useSearchParams()
    let width = 300
    let height = 200
    let cursorSize = 25
    let os = 'ios' as 'ios' | 'android' | 'web' | undefined
    const imageUrl = searchParams.get("url")
    try {
        width = parseInt(searchParams.get("w"), 10)
        height = parseInt(searchParams.get("h"), 10)
        cursorSize = parseInt(searchParams.get("cursorsize"), 10)
        os = searchParams.get("os") as 'ios' as 'ios' | 'android' | 'web' | undefined
    } catch (e) {
        console.log(e)
    }

    return (<>
        <MaskEditor
            //src="https://placekitten.com/256/256"
            //src="https://cdn-bnokp.nitrocdn.com/QNoeDwCprhACHQcnEmHgXDhDpbEOlRHH/assets/images/optimized/rev-d96773f/www.decorilla.com/online-decorating/wp-content/uploads/2022/10/Interior-design-styles-Eclectic-contemporary-living-room-by-Jamie-C.jpg"
            src={imageUrl}
            canvasRef={canvas}
            cursorSize={cursorSize}
            maskBlendMode='normal'
            maskOpacity={0.85}
            width={width}
            height={height}
            os={os}
        />
        {/* <button
            onClick={() => {
                const base64Image = toMask(canvas.current);
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(base64Image);
                }
            }}
        >
            Get Mask
        </button> */}
    </>
    )
}


export default ImageMaskPage
import React from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import logo from './logo.svg';
import './App.css';
import AuthPage from './pages/AuthPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from './config';
import DashboardPage from './pages/DashboardPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PricingPage from './pages/PricingPage';
import PreAuthPage from './pages/PreAuthPage';
import ImageMaskPage from './pages/ImageMaskPage';


const queryClient = new QueryClient()

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        {/* <AuthPage /> */}
        <Router>
          <Routes>
            <Route path="/" element={<PreAuthPage />} />
            <Route path="/login" element={<AuthPage />} />
            <Route path="/mask" element={<ImageMaskPage />} />
            {/* <Route path="/" element={<PricingPage />} /> */}
          </Routes>
        </Router>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;



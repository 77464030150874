import * as React from "react";
import "./maskEditor.less";
import { hexToRgb, toMask } from "./utils";

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage(data: string): void;
    };
  }
}

export interface MaskEditorProps {
  src: string;
  canvasRef?: React.MutableRefObject<HTMLCanvasElement>;
  cursorSize?: number;
  onCursorSizeChange?: (size: number) => void;
  maskOpacity?: number;
  maskColor?: string;
  maskBlendMode?: "normal" | "multiply" | "screen" | "overlay" | "darken" | "lighten" | "color-dodge" | "color-burn" | "hard-light" | "soft-light" | "difference" | "exclusion" | "hue" | "saturation" | "color" | "luminosity"
  width?: number;
  height?: number;
  os?: "ios" | "android" | "web";
}

export const MaskEditorDefaults = {
  cursorSize: 10,
  maskOpacity: .75,
  maskColor: "#23272d",
  maskBlendMode: "normal",
}


type PostMessageToRNType = {
  type: 'mask' | 'status';
  status?: 'loaded' | undefined;
  base64Image?: string;
}

type PostMessageToWebviewType = {
  type: 'cursor' | 'reset';
  cursorSize?: number;
}

let lastX = undefined; let lastY = undefined;

export const MaskEditor: React.FC<MaskEditorProps> = (props: MaskEditorProps) => {
  const src = props.src;
  //const cursorSize = props.cursorSize ?? MaskEditorDefaults.cursorSize;
  const maskColor = props.maskColor ?? MaskEditorDefaults.maskColor;
  const maskBlendMode = props.maskBlendMode ?? MaskEditorDefaults.maskBlendMode;
  const maskOpacity = props.maskOpacity ?? MaskEditorDefaults.maskOpacity;

  const canvas = React.useRef<HTMLCanvasElement | null>(null);
  const maskCanvas = React.useRef<HTMLCanvasElement | null>(null);
  const cursorCanvas = React.useRef<HTMLCanvasElement | null>(null);
  const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(null);
  const [maskContext, setMaskContext] = React.useState<CanvasRenderingContext2D | null>(null);
  const [cursorContext, setCursorContext] = React.useState<CanvasRenderingContext2D | null>(null);
  const [size, setSize] = React.useState<{ x: number, y: number }>({ x: props?.width || 256, y: props?.height || 256 })

  const cursorSize = React.useRef<number>(props.cursorSize ?? MaskEditorDefaults.cursorSize);
  // we listen post message comming from RN
  const listenPostMessage = () => {
    const getPostMessage = (event: any) => {
      try {
        const message: PostMessageToWebviewType = JSON.parse(event?.data)
        if (message.type) {
          if (message.type === 'cursor' && message.cursorSize && message.cursorSize > 0)
            cursorSize.current = message.cursorSize
          else if (message.type === 'reset') {
            //alert("coucou")
            //initMakeMaskContext()
            maskContext?.clearRect(0, 0, props.width, props.height);
            cursorContext?.clearRect(0, 0, props.width, props.height);
            maskCanvas?.current?.getContext("2d")?.clearRect(0, 0, props.width, props.height);
            //alert("ok")
          }
        }
      }
      catch (e) {
        //alert(e.message)
        console.log(e)
      }
    }

    if (props.os === undefined || props.os === 'android' || props.os === 'web') {
      document.addEventListener('message', function (event: any) {
        getPostMessage(event)
      });
    } else if (props.os === 'ios') {
      // For iOS, you might need to listen to 'message' on window instead
      window.addEventListener('message', function (event: any) {
        getPostMessage(event)
      });
    }
  }
  React.useEffect(() => {
    listenPostMessage()
  }, [])

  const saveMask = () => {
    const base64Image = toMask(maskCanvas.current);
    if (window.ReactNativeWebView) {
      const message: PostMessageToRNType = { type: 'mask', base64Image }
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
  }

  const sendIsLoaded = () => {
    if (window.ReactNativeWebView) {
      const message: PostMessageToRNType = { type: 'status', status: 'loaded' }
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
  }

  React.useLayoutEffect(() => {
    if (canvas.current && !context) {
      const ctx = (canvas.current as HTMLCanvasElement).getContext("2d");
      setContext(ctx);
    }
  }, [canvas]);

  React.useLayoutEffect(() => {
    if (maskCanvas.current && !context) {
      const ctx = (maskCanvas.current as HTMLCanvasElement).getContext("2d");
      if (ctx) {
        ctx.fillStyle = src && src != "" ? "#ffffff" : 'transparent';
        ctx.fillRect(0, 0, size.x, size.y);
      }
      setMaskContext(ctx);
    }
  }, [maskCanvas]);

  React.useLayoutEffect(() => {
    if (cursorCanvas.current && !context) {
      const ctx = (cursorCanvas.current as HTMLCanvasElement).getContext("2d");
      setCursorContext(ctx);
    }
  }, [cursorCanvas]);

  React.useEffect(() => {
    const drawImage = () => {
      if (src && context) {
        const img = new Image;
        img.onload = async evt => {
          let imgWidth = img.width;
          let imgHeight = img.height;
          if (props.width && props.height) { // by default, use image size
            imgWidth = props.width;
            imgHeight = props.height;
          }
          setSize({ x: imgWidth, y: imgHeight });

          setTimeout(() => { // JDT fix for big images
            context?.drawImage(img, 0, 0, imgWidth, imgHeight);
            sendIsLoaded()
          }, 10);
        }
        img.src = src;
      }
    }
    if (src && src !== "")
      drawImage()
    else sendIsLoaded()
    //drawImage()

  }, [src, context]);

  // Pass mask canvas up
  React.useLayoutEffect(() => {
    if (props.canvasRef) {
      props.canvasRef.current = maskCanvas.current;
    }
  }, [maskCanvas]);


  React.useEffect(() => {

    const handleMouseEvent = (evt, isTouch = false) => {

      const x = isTouch ? evt.touches[0].clientX : evt.offsetX;
      const y = isTouch ? evt.touches[0].clientY : evt.offsetY;

      // if (cursorContext) {
      //   cursorContext.clearRect(0, 0, size.x, size.y);
      //   cursorContext.beginPath();
      //   cursorContext.fillStyle = `${maskColor}88`;
      //   cursorContext.strokeStyle = maskColor;
      //   cursorContext.arc(x, y, cursorSize, 0, 360);
      //   cursorContext.fill();
      //   cursorContext.stroke();
      // }

      if (maskContext && (evt.buttons > 0 || isTouch)) {
        // maskContext.beginPath();
        // maskContext.fillStyle = isTouch ? maskColor : (evt.buttons > 1 || evt.shiftKey) ? "#ffffff" : maskColor;
        // maskContext.arc(x, y, cursorSize, 0, 360);
        // maskContext.fill();

        maskContext.beginPath();
        maskContext.strokeStyle = maskColor;
        maskContext.fillStyle = isTouch ? maskColor : (evt.buttons > 1 || evt.shiftKey) ? "#ffffff" : maskColor;
        maskContext.lineWidth = cursorSize.current;
        maskContext.lineJoin = "round";
        maskContext.moveTo(lastX, lastY);
        maskContext.lineTo(x, y);
        maskContext.closePath();
        maskContext.stroke();
        lastX = x; lastY = y;
      }
    };

    const scrollListener = (evt: WheelEvent) => {
      if (cursorContext) {
        props.onCursorSizeChange(Math.max(0, cursorSize.current + (evt.deltaY > 0 ? 1 : -1)));

        cursorContext.clearRect(0, 0, size.x, size.y);

        cursorContext.beginPath();
        cursorContext.fillStyle = `${maskColor}88`;
        cursorContext.strokeStyle = maskColor;
        cursorContext.arc(evt.offsetX, evt.offsetY, cursorSize.current, 0, 360);
        cursorContext.fill();
        cursorContext.stroke();

        evt.stopPropagation();
        evt.preventDefault();
      }
    }

    const mouseListener = (evt) => handleMouseEvent(evt, false);
    const touchListener = (evt) => {
      evt.preventDefault(); // Prevents default touch behavior (like scrolling)
      handleMouseEvent(evt, true);
    };
    const touchEndListener = (evt) => {
      lastX = undefined; lastY = undefined;
      saveMask()
    };

    cursorCanvas.current?.addEventListener("mousemove", mouseListener);
    cursorCanvas.current?.addEventListener("touchmove", touchListener);
    cursorCanvas.current?.addEventListener("touchend", touchEndListener);
    if (props.onCursorSizeChange) {
      cursorCanvas.current?.addEventListener("wheel", scrollListener);
    }
    return () => {
      cursorCanvas.current?.removeEventListener("mousemove", mouseListener);
      cursorCanvas.current?.removeEventListener("touchmove", touchListener);
      cursorCanvas.current?.removeEventListener("touchend", touchEndListener);

      if (props.onCursorSizeChange) {
        cursorCanvas.current?.removeEventListener("wheel", scrollListener);
      }

    }
  }, [cursorContext, maskContext, cursorCanvas, cursorSize.current, maskColor, size]);

  const replaceMaskColor = React.useCallback((hexColor: string, invert: boolean) => {
    const imageData = maskContext?.getImageData(0, 0, size.x, size.y);
    const color = hexToRgb(hexColor);
    if (imageData) {
      for (var i = 0; i < imageData?.data.length; i += 4) {
        const pixelColor = ((imageData.data[i] === 255) != invert) ? [255, 255, 255] : color;
        imageData.data[i] = pixelColor[0];
        imageData.data[i + 1] = pixelColor[1];
        imageData.data[i + 2] = pixelColor[2];
        imageData.data[i + 3] = imageData.data[i + 3];
      }
      maskContext?.putImageData(imageData, 0, 0);
    }
  }, [maskContext]);
  React.useEffect(() => replaceMaskColor(maskColor, false), [maskColor]);
  return <div className="react-mask-editor-outer">
    <div
      className="react-mask-editor-inner"
      style={{
        width: size.x,
        height: size.y,
      }}
    >
      {src && src != "" ? <canvas
        ref={canvas}
        style={{
          width: size.x,
          height: size.y,
        }}
        width={size.x}
        height={size.y}
        className="react-mask-editor-base-canvas"
      /> : null}
      <canvas
        ref={maskCanvas}
        width={size.x}
        height={size.y}
        style={{
          width: size.x,
          height: size.y,
          opacity: maskOpacity,
          mixBlendMode: maskBlendMode as any,
        }}
        className="react-mask-editor-mask-canvas"
      />
      <canvas
        ref={cursorCanvas}
        width={size.x}
        height={size.y}
        style={{
          width: size.x,
          height: size.y,
        }}
        className="react-mask-editor-cursor-canvas"
      />
    </div>
  </div>
}

import React, { useEffect, useState } from 'react'
import useUserState, { UserType } from '../hooks/useUserState'
import AccountPage from './AccountPage'
import GoogleAuth from '../components/auth/GoogleAuth'
import { getJWTToken, saveJWTToken } from '../modules/secure'
import { fetchValidJWT } from '../api/ApiAuth'
import AppleAuth from '../components/auth/AppleAuth'
import config from '../config'
import DashboardPage from './DashboardPage'
import PricingPage from './PricingPage'
import Layout from './Layout'
import SubscriptionPage from './SubscriptionPage'
import FacebookAuth from '../components/auth/FacebookAuth'

type Props = {}

const AuthPage = (props: Props) => {
    const { userState, setUserState } = useUserState()
    const [authStatus, setAuthStatus] = useState<any>("idle")


    useEffect(() => {
        const getToken = async () => {
            const data = getJWTToken()
            if (!data) {
                setAuthStatus('nojwt')
                return
            }
            const res = await fetchValidJWT({ jwtToken: data, configCache: null })
            if (!res || !res["id"])
                setAuthStatus('nojwt')
            else {
                setUserState({ ...res, jwtToken: data })
                setAuthStatus('success')
            }
        }

        getToken()

    }, [])


    const handleUser = async (data: {
        jwt_token: string;
        user: UserType;
    } | null) => {
        if (!data || !data["user"] || !data["user"].id || !data["jwt_token"]) {
            setAuthStatus("error")
            setUserState(null)
        }
        else {
            saveJWTToken(data["jwt_token"])
            setUserState({ ...data["user"], jwtToken: data["jwt_token"] })
            setAuthStatus("success")
        }
    }

    if (authStatus === 'success' && typeof userState === 'object') {
        if (userState.status > 1)
            return <SubscriptionPage userState={userState} />
        else
            return (
                <PricingPage />
            )
    }
    if (authStatus === 'idle')
        return <Layout><div className='flex justify-center items-center'>Chargement ...</div></Layout>

    return (
        <div className='flex-1 flex-col h-screen w-full bg-primary flex justify-center items-center p-4'>
            <a href='https://hoqi.app'><img className='h-32' src='/logo-white.png' alt='logo' /></a>
            <div className='text-white mt-8 max-w-md'><strong>Important :</strong> Connectez-vous ici avec le <strong>même compte</strong> que
                sur l'app mobile
                (<a className='underline' href="https://apps.apple.com/fr/app/hoqi-lia-pour-limmobilier/id6448754506" target='_blank'>App Store</a> ou <a className='underline' href="https://play.google.com/store/apps/details?id=app.homni.homni" target='_blank'>Play Store</a>) pour gérer votre abonnement.</div>
            <div className='h-6' />
            <GoogleAuth onUser={handleUser} />
            <div className='h-6' />
            <AppleAuth onUser={handleUser} />
            <div className='h-6' />
            <FacebookAuth onUser={handleUser} />
            <div className='h-16' />
            <div className='text-white text-xs max-w-md'>
                En cliquant sur le bouton "Se connecter", j'accepte expressément
                les <a className='underline' href={'https://dashboard.hoqi.app/terms.html'} target='_blank'>Conditions d'Utilisation</a> de Hoqi
                et je comprends que mes informations de compte seront utilisées conformément à
                la <a className='underline' href={'https://dashboard.hoqi.app/privacy.html'} target='_blank'>Politique de Confidentialité</a> de Hoqi.
            </div>
            <div className='text-xs mt-8 text-white text-center'><span className='font-bold'>Une question ?</span> <a className='underline hover:no-underline' href="mailto:contact@hoqi.app">contact@hoqi.app</a>
            </div>
        </div>
    )
}

export default AuthPage